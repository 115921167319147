import React, { lazy } from 'react'
import { InterviewDetailsIntegrationsProps } from './InterviewDetailsIntegrations'
const LazyInterviewDetailsIntegrations = lazy(() => import('./InterviewDetailsIntegrations'))

const InterviewDetailsIntegrations = (
    props: JSX.IntrinsicAttributes & {
        children?: React.ReactNode
    } & InterviewDetailsIntegrationsProps,
) => <LazyInterviewDetailsIntegrations {...props} />

export default InterviewDetailsIntegrations
