import React, { lazy } from 'react'
import { InterviewDetailsResponsesProps } from './InterviewDetailsResponses'
const LazyInterviewDetailsResponses = lazy(() => import('./InterviewDetailsResponses'))

const InterviewDetailsResponses = (
    props: JSX.IntrinsicAttributes & {
        children?: React.ReactNode
    } & InterviewDetailsResponsesProps,
) => <LazyInterviewDetailsResponses {...props} />

export default InterviewDetailsResponses
