import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import JobPostingDetail from 'pages/JobBoard/pages/JobPostings/pages/JobPostingDetail/JobPostingDetail'
import { RoleRepPromptFormTemplate, RoleRepPromptQuestion } from './role_rep_prompt_service'
import type { GetPlaceResponse } from '@aws-sdk/client-location'
import { useMemo } from 'react'
import { ExternalService, InterviewStage } from './external_integrations_service'
import { createdJobPostingEvent } from './intercom_event_tracking'

const JobPostingStatuses = ['draft', 'active', 'closed'] as const
type JobPostingStatus = (typeof JobPostingStatuses)[number]

export interface JobPosting {
    readonly token: string
    name: string
    archetype_name: string
    archetype_token: string
    status: JobPostingStatus
    created: string
    trigger_stage?: string // note: this is the stage id, _not_ name
    info: {
        externalJobInfo?: {
            job_id: string
            internal_id: string
            service_id: ExternalService['id']
        }
    }
}

export type Place = GetPlaceResponse['Place'] & {
    placeId: string
}
export interface JobPostingDetail {
    readonly token: string
    name: string
    archetype: string
    archetype_name: string
    archetype_is_draft: boolean
    status: JobPostingStatus
    creator: string // token of a user
    created: string
    info: {
        dueDateNumDays?: number
        description?: string
        skills?: string[]
        salaryRange?: [number, number]
        salaryType?: 'perYear' | 'perHour' | 'perContract'
        jobType?: 'fullTime' | 'contract' | 'other'
        remote?: 'remote' | 'notRemote'
        officeLocations?: Place[]
        applicantLocations?: Place[]
        sourceUrl?: string
        externalJobInfo?: {
            job_id: string
            service_id: string
            internal_id: string
            trigger_stage?: number | string // id of the stage.  GH has it as a number
        }
    }
    settings: {
        qualifyingAnswers?: Record<string, number[]> // this is question token: [idxs of answers marked Good]
    }
    qualification_form?: string // token of an rrpft
}

export const useJobPostings = (productMode: 'async' | 'candidatePool') => {
    const _get = async () => {
        const res = await axios.get<JobPosting[]>('/jb_api/job_postings/' + productMode)
        return res.data
    }

    const query = useQuery({ queryKey: ['jobPostings', productMode], queryFn: _get })
    return query
}

export const jobPostingQueryArg = (productMode: 'async' | 'candidatePool', token?: string) => {
    const _get = async (token: string) => {
        const res = await axios.get<JobPostingDetail>(
            `/jb_api/job_postings/${productMode}/${token}`,
        )
        return res.data
    }

    return {
        queryKey: ['jobPostings', productMode, token],
        queryFn: () => _get(token || ''),
        enabled: !!token,
    }
}
export const useJobPosting = (productMode: 'async' | 'candidatePool', token?: string) => {
    return useQuery(jobPostingQueryArg(productMode, token))
}

interface CreateJobPostingType {
    name: string
    archetype: string
}

export const useCreateJobPosting = () => {
    const _post = async (name: string, archetype: string) => {
        const res = await axios.post<JobPostingDetail>('/jb_api/job_postings/candidatePool', {
            name,
            archetype,
        })
        return res.data
    }
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: ({ name, archetype }: CreateJobPostingType) => _post(name, archetype),
        onMutate: () => queryClient.cancelQueries({ queryKey: ['jobPostings'] }),
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['jobPostings'] })
        },
    })
    return mutation
}

export const useCreateAsyncInterviewJob = () => {
    const _post = async (
        name: string,
        externalJobId: string,
        externalInternalJobId: string,
        serviceId: string,
        prompt_form_token?: string,
    ) => {
        const res = await axios.post<JobPostingDetail>('/jb_api/job_postings/async', {
            name,
            externalJobId,
            externalInternalJobId,
            serviceId,
            prompt_form_token,
        })
        return res.data
    }
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: ({
            name,
            externalJobId,
            externalInternalJobId,
            serviceId,
            prompt_form_token,
        }: {
            name: string
            externalJobId: string
            externalInternalJobId: string
            serviceId: string
            prompt_form_token?: string
        }) => _post(name, externalJobId, externalInternalJobId, serviceId, prompt_form_token),
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey: ['jobPostings'] })
            await queryClient.cancelQueries({ queryKey: ['company_details_for_company'] })
        },
        onSuccess: (
            jobPost,
            {
                name,
                externalJobId,
                externalInternalJobId,
                serviceId,
            }: {
                name: string
                externalJobId: string
                externalInternalJobId: string
                serviceId: string
                prompt_form_token?: string
            },
        ) => {
            createdJobPostingEvent(jobPost.token, !externalJobId)
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['jobPostings'] })
            queryClient.invalidateQueries({ queryKey: ['company_details_for_company'] })
        },
    })
    return mutation
}

export const useUpdateJobTriggerStage = () => {
    // this is meant to be used to update the trigger stage from the list endpoint, where it doesnt have info to just mutate
    const _patch = async (token: string, trigger_stage: string | undefined) => {
        const res = await axios.patch<JobPostingDetail>('/jb_api/job_postings/async/' + token, {
            trigger_stage: trigger_stage ?? null, // undefined is what the select returns on clear, but that would drop it out of the body here so ok.
        })
        return res.data
    }
    const queryClient = useQueryClient()

    const mutation = useMutation({
        mutationFn: ({
            token,
            trigger_stage,
        }: {
            token: string
            trigger_stage: string | undefined
        }) => _patch(token, trigger_stage),
        onMutate: () => queryClient.cancelQueries({ queryKey: ['jobPostings'] }),
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['jobPostings'] })
        },
    })
    return mutation
}

export const useUpdateJobPosting = (productMode: 'candidatePool' | 'async') => {
    const _patch = async (
        token: string,
        patch: Partial<JobPostingDetail> & { staffArchetypeName?: string },
    ) => {
        const res = await axios.patch<JobPostingDetail>(
            `/jb_api/job_postings/${productMode}/${token}`,
            patch,
        )
        return res.data
    }
    const queryClient = useQueryClient()

    const mutation = useMutation({
        mutationFn: ({
            token,
            patch,
        }: {
            token: string
            patch: Partial<JobPostingDetail> & { staffArchetypeName?: string }
        }) => _patch(token, patch),
        onMutate: () => queryClient.cancelQueries({ queryKey: ['jobPostings'] }),
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['jobPostings'] })
        },
    })
    return mutation
}

export const usePromptFormTemplateForJobBoard = (token?: string) => {
    const _get = async (token: string) => {
        const res = await axios.get<RoleRepPromptFormTemplate>(
            '/jb_api/role_rep_prompt_form_templates/' + token,
        )
        return res.data
    }

    const query = useQuery({
        queryKey: ['role_rep_prompt_form_templates', 'job_board', token],
        queryFn: () => _get(token ?? ''),
        enabled: !!token,
    })
    return query
}

export const useUpdatePromptFormTemplateForJobBoard = () => {
    const _patch = async (token: string, prompt_questions: RoleRepPromptQuestion[]) => {
        const res = await axios.patch<RoleRepPromptFormTemplate>(
            '/jb_api/role_rep_prompt_form_templates/' + token,
            { prompt_questions },
        )
        return res.data
    }
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: ({
            token,
            promptQuestions,
        }: {
            token: string
            promptQuestions: RoleRepPromptQuestion[]
        }) => _patch(token, promptQuestions),
        onMutate: () =>
            queryClient.cancelQueries({
                queryKey: ['role_rep_prompt_form_templates', 'job_board'],
            }),
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['role_rep_prompt_form_templates', 'job_board'],
            })
        },
    })
    return mutation
}

type CreatePromptQuestionType = Pick<RoleRepPromptQuestion, 'prompt' | 'qtype' | 'info'>

export const useCreateQualificationQuestion = (jp_token: string) => {
    const _post = async (question: CreatePromptQuestionType) => {
        const res = await axios.post<RoleRepPromptQuestion>(
            '/jb_api/prompt_questions/' + jp_token,
            question,
        )
        return res.data
    }

    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: (question: CreatePromptQuestionType) => _post(question),
        onMutate: async () => {
            await queryClient.cancelQueries({
                queryKey: ['role_rep_prompt_form_templates', 'job_board'],
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['role_rep_prompt_form_templates', 'job_board'],
            })
        },
    })
    return mutation
}

export const useUpdateQualificationQuestion = (jp_token: string) => {
    const _patch = async (token: string, patch: Partial<RoleRepPromptQuestion>) => {
        const res = await axios.patch<RoleRepPromptQuestion>(
            '/jb_api/prompt_questions/' + jp_token + '/' + token,
            patch,
        )
        return res.data
    }
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: ({ token, patch }: { token: string; patch: Partial<RoleRepPromptQuestion> }) =>
            _patch(token, patch),
        onMutate: async () => {
            await queryClient.cancelQueries({
                queryKey: ['role_rep_prompt_form_templates', 'job_board'],
            })
            // this could end up needing to alter the Good Answers of a job board
            await queryClient.cancelQueries({
                queryKey: ['job_postings'],
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['role_rep_prompt_form_templates', 'job_board'],
            })
            queryClient.invalidateQueries({
                queryKey: ['job_postings'],
            })
        },
    })
    return mutation
}

interface JobPostingsFilters {
    search: string
    filters: {
        archetype?: string[]
    }
}
export const useJobPostingsFilters = () => {
    const { data } = useQuery<JobPostingsFilters>({
        queryKey: ['job_postings_list', 'filters'],
        initialData: { search: '', filters: {} },
        gcTime: Infinity,
        staleTime: Infinity,
    })

    const queryClient = useQueryClient()

    return useMemo(
        () =>
            [
                data,
                (newData: JobPostingsFilters) => {
                    queryClient.setQueryData(['job_postings_list', 'filters'], newData)
                },
            ] as const,
        [data, queryClient],
    )
}
