import React, { lazy } from 'react'
import { InterviewDetailsRequestsProps } from './InterviewDetailsRequests'
const LazyInterviewDetailsRequests = lazy(() => import('./InterviewDetailsRequests'))

const InterviewDetailsRequests = (
    props: JSX.IntrinsicAttributes & {
        children?: React.ReactNode
    } & InterviewDetailsRequestsProps,
) => <LazyInterviewDetailsRequests {...props} />

export default InterviewDetailsRequests
